import React, { useEffect, useState } from "react";
import {
  Link,
  Route,
  Switch,
  useParams,
  useRouteMatch,
} from "react-router-dom";
import { LinkContainer } from "react-router-bootstrap";
import { Card, Nav, NavItem } from "react-bootstrap";
import { loadSchoolDetail } from "../../actions";
import SchoolDashboard from "../../components/school/schoolDetail/SchoolDashboard";
import SchoolUserPanel from "../../components/school/schoolDetail/SchoolUserPanel";
import SchoolDetail from "../../components/school/schoolDetail/SchoolDetail";
import { RoleType } from "../../utils/constants";
import { ChevronRight } from "react-bootstrap-icons";
import ClassroomListTable from "../insight/classroom/ClassroomListTable";

const SchoolContentPanels = ({ schoolRef }) => {
  let { path } = useRouteMatch();
  return (
    <div className="mt-5 py-4 px-3 bg-soft-secondary">
      <Switch>
        <Route
          exact
          path={`${path}/dashboard`}
          children={<SchoolDashboard schoolRef={schoolRef} />}
        />
        <Route
          exact
          key="show-classrooms"
          path={`${path}/classrooms`}
          children={<ClassroomListTable schoolRef={schoolRef} />}
        />
        <Route
          exact
          key="show-teachers"
          path={`${path}/teachers`}
          children={
            <SchoolUserPanel role={RoleType.TEACHER} schoolRef={schoolRef} />
          }
        />
        <Route
          exact
          key="show-students"
          path={`${path}/students`}
          children={
            <SchoolUserPanel role={RoleType.STUDENT} schoolRef={schoolRef} />
          }
        />
        <Route
          exact
          path={`${path}/school-details`}
          children={<SchoolDetail schoolRef={schoolRef} />}
        />
      </Switch>
    </div>
  );
};

const SchoolTabs = ({ schoolRef }) => {
  let { url } = useRouteMatch();
  return (
    <Nav variant="tabs" className="justify-content-center">
      <Nav.Item>
        <LinkContainer to={`${url}/dashboard`}>
          <Nav.Link>Dashboard</Nav.Link>
        </LinkContainer>
      </Nav.Item>
      <NavItem>
        <LinkContainer to={`${url}/classrooms`}>
          <Nav.Link>Classrooms</Nav.Link>
        </LinkContainer>
      </NavItem>
      <NavItem>
        <LinkContainer to={`${url}/teachers`}>
          <Nav.Link>Teachers</Nav.Link>
        </LinkContainer>
      </NavItem>
      <NavItem>
        <LinkContainer to={`${url}/students`}>
          <Nav.Link>Students</Nav.Link>
        </LinkContainer>
      </NavItem>
      <NavItem>
        <LinkContainer to={`${url}/school-details`}>
          <Nav.Link>School Details</Nav.Link>
        </LinkContainer>
      </NavItem>
    </Nav>
  );
};

const SchoolDetailPage = () => {
  const { schoolRef } = useParams();
  const [schoolDetailData, setSchoolDetailData] = useState(null);
  useEffect(() => {
    loadSchoolDetail(schoolRef, (data) => {
      loadSchoolDetail(data.schoolRef, (data) => {
        setSchoolDetailData(data);
      });
    });
  }, [schoolRef]);
  return (
    schoolDetailData && (
      <Card className="mt-3">
        <div className="pt-4 px-3">
          <h4 className="font-weight-bold">
            <Link to={`/admin/schools`}>
              <u className="h5">All Schools</u>
            </Link>
            <ChevronRight className="mx-2" />
            {schoolDetailData.schoolName}
          </h4>
        </div>

        <SchoolTabs schoolRef={schoolRef} />
        <SchoolContentPanels schoolRef={schoolRef} />
      </Card>
    )
  );
};

export default SchoolDetailPage;
