import React from "react";
import { Button } from "react-bootstrap";
import { Tooltip } from "antd";
import { FiletypePpt } from "react-bootstrap-icons";

const PPTLinkActionButton = ({ url, isResponsive = false }) => {
  const handleClick = () => {
    window.open(url, "_blank").focus();
  };
  const buttonStyles = [
    { size: "sm", className: "d-lg-inline d-none" },
    { size: "xs", className: "d-lg-none" },
  ];

  const SlidesButton = ({ size = "sm", additionalClassName = "" }) => {
    return (
      <Tooltip
        title="Click here to see the slides for this unit"
        placement="bottom"
      >
        <Button
          variant="warning"
          className={
            "m-0 rounded-circle btn-icon-only text-white shadow hover-gray-400 hover-text-warning " +
            additionalClassName
          }
          size={size}
          style={{
            backgroundColor: "rgba(0, 0, 0, 0.4)",
          }}
          onClick={handleClick}
        >
          <FiletypePpt className="pb-1" style={{ fontSize: "1.5rem" }} />
        </Button>
      </Tooltip>
    );
  };

  return (
    <>
      {!isResponsive ? (
        <SlidesButton />
      ) : (
        buttonStyles.map((item, index) => (
          <SlidesButton
            size={item.size}
            key={index}
            additionalClassName={item.className}
          />
        ))
      )}
    </>
  );
};

export default PPTLinkActionButton;
