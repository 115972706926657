import React, { useState, useEffect } from "react";
import { Card, Carousel, Row, Col } from "react-bootstrap";
import { useToasts } from "react-toast-notifications";
import { useCookieState } from "ahooks";
import { useHistory } from "react-router-dom";
import { signIn, loadCurrentUserDetail } from "../../actions";
import {
  ILLUSTRATIONS,
  MAX_COOKIE_AGE,
  RoleType,
  WEBSITE_LINKS,
} from "../../utils/constants";
import OTPSignIn from "./OTPSignIn";
import SignInForm from "./SignInForm";
import { setGAUserId } from "../../utils/thirdParty/GoogleAnalyticsUtils";

const SignIn = () => {
  const [isOTPMode, setIsOTPMode] = useState(false);
  const [credential, setCredential] = useState(null);
  const [token, setToken] = useCookieState("escs");
  const setTokenExp = useCookieState("exp")[1];
  const [userDetail, setUserDetail] = useState(null);
  const [needSignIn, setNeedSignIn] = useState(null);
  const { addToast } = useToasts();
  const history = useHistory();

  useEffect(() => {
    if (token) {
      loadCurrentUserDetail(
        (data) => {
          setUserDetail(data);
        },
        () => {
          setToken(null);
          setTokenExp(null);
          setNeedSignIn(true);
        }
      );
    } else setNeedSignIn(true);
  }, []);

  useEffect(() => {
    if (userDetail) {
      switch (userDetail.userInfo.roleTypeName) {
        case RoleType.ADMIN:
          history.push("/admin/dashboard");
          break;
        case RoleType.TEACHER:
          history.push("/teacher");
          break;
        default:
          history.push("/student");
          break;
      }
    }
  }, [userDetail]);

  const handleSubmit = (event) => {
    event.preventDefault();
    const form = event.target;
    const data = {
      username: form.elements.email.value.trim().toLowerCase(),
      password: form.elements.password.value,
    };
    signIn(
      data,
      (data) => {
        if ("action" in data) {
          // Redirect to mfa page
          setCredential(data["token"]);
          setIsOTPMode(true);
          return;
        }
        const exp = new Date(+new Date() + MAX_COOKIE_AGE);
        setToken(data.token, {
          expires: (() => exp)(),
        });
        setTokenExp(exp);
        setGAUserId(data.user.id);
        setUserDetail(data.user);
      },
      (data) => {
        addToast("Invalid email or password.", {
          appearance: "error",
          autoDismiss: true,
        });
      }
    );
  };

  const getImageCarousel = () => {
    const textList = [
      "Learning for the real world",
      "Learning for the 21st Century",
      "Student-centred learning that works",
    ];
    const imageInfoList = textList.map((item, index) => {
      return { src: ILLUSTRATIONS.SIGN_IN_SLIDER[index], text: item };
    });
    const carouselStype = {
      height: "31rem",
      width: "31rem",
      backgroundColor: "white",
    };
    return (
      <Carousel className="d-none d-lg-block" style={carouselStype}>
        {imageInfoList.map((imageInfo, index) => (
          <Carousel.Item key={index}>
            <img
              className="d-block w-100"
              src={imageInfo.src}
              alt={imageInfo.text}
              height="495"
            />
            <Carousel.Caption>
              <p className="text-primary">{imageInfo.text}</p>
            </Carousel.Caption>
          </Carousel.Item>
        ))}
      </Carousel>
    );
  };

  return (
    needSignIn && (
      <div className="container-fluid container-application">
        <div className="page-content">
          <div className="min-vh-100 py-5 d-flex align-items-center">
            <div className="w-100">
              <Row className="justify-content-center">
                <Col sm="8" md="12">
                  <div className="d-flex justify-content-center">
                    <Card
                      className="shadow zindex-100 mb-0"
                      style={{ height: "31rem", width: "26rem" }}
                    >
                      <Card.Body className="px-md-5 py-5">
                        <div>
                          <img
                            src={ILLUSTRATIONS.LOGO.MAIN_LOGO}
                            alt="logo"
                            height="24"
                            className="cursor-pointer"
                            onClick={() => {
                              window.location.href = WEBSITE_LINKS.ROOT;
                            }}
                          />
                          <p className="text-muted mb-5 mt-3">
                            Sign in to your account
                          </p>
                        </div>
                        {isOTPMode ? (
                          <OTPSignIn
                            credential={credential}
                            handleUserDetailChange={(user) =>
                              setUserDetail(user)
                            }
                          />
                        ) : (
                          <>
                            <SignInForm handleSubmit={handleSubmit} />
                            <div className="mt-3 text-sm">
                              Don't have a teacher account?{" "}
                              <a href="/signup">
                                <u>Sign up</u>
                              </a>
                            </div>
                          </>
                        )}
                      </Card.Body>
                    </Card>
                    {getImageCarousel()}
                  </div>
                </Col>
              </Row>
            </div>
          </div>
        </div>
      </div>
    )
  );
};

export default SignIn;
