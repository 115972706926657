import React, { useState, useEffect } from "react";
import { loadSearchableCourseList, loadFavouriteList } from "../../actions";
import {
  AWS_REGION_COUNTRY_MAP,
  EXPLORE_PAGE_MODES,
  RoleType,
} from "../../utils/constants";
import ExplorePanel from "../../components/explore/ExplorePanel";
import SearchPanel from "../../components/explore/SearchPanel";
import FavouritePanel from "../../components/explore/FavouritePanel";
import { useDispatch, useSelector } from "react-redux";
import { FAVOURITE_COURSES } from "../../actions/types";
import camelCaseRecursive from "../../utils/CamelCaseRecursive";
import { requestJsonObject } from "../../api/apis";

const ExploreApp = ({ isPublic = false }) => {
  const [courseList, setCourseList] = useState(null);
  const [mode, setMode] = useState(EXPLORE_PAGE_MODES.EXPLORE);
  const [redirectFilter, setRedirectFilter] = useState(null);
  const dispatch = useDispatch();
  const isTeacher = useSelector(
    (state) =>
      state.auth.userDetail &&
      state.auth.userDetail.userInfo.roleTypeName === RoleType.TEACHER
  );

  const handleCoursesLoaded = (data) => {
    setCourseList({
      courses: data.courses.filter(
        (course) =>
          course.countryMetadata &&
          AWS_REGION_COUNTRY_MAP[window._env_["REGION"]] in
            course.countryMetadata
      ),
      projectDict: data.projectDict,
    });
  };

  useEffect(() => {
    if (isPublic) {
      const { CONTENT_BUCKET_CDN_DOMAIN } = window._env_;
      requestJsonObject(
        CONTENT_BUCKET_CDN_DOMAIN + "/public/content/all-courses.json",
        (data) => {
          handleCoursesLoaded(camelCaseRecursive(data));
        }
      );
      return;
    }

    loadSearchableCourseList(null, handleCoursesLoaded);
  }, []);

  useEffect(() => {
    isTeacher &&
      loadFavouriteList((data) => {
        dispatch({ type: FAVOURITE_COURSES, payload: data });
      });
  }, [isTeacher]);

  useEffect(() => {
    // Reset filter if back to explore
    if (mode === EXPLORE_PAGE_MODES.EXPLORE) {
      setRedirectFilter(null);
    }
  }, [mode]);

  const switchMode = (mode) => {
    if (!courseList) {
      return null;
    }
    const genericProps = {
      courseList: courseList,
      setMode: setMode,
      isPublic: isPublic,
    };
    switch (mode) {
      case EXPLORE_PAGE_MODES.EXPLORE:
        return (
          <ExplorePanel
            handleFilterUpdate={setRedirectFilter}
            {...genericProps}
          />
        );
      case EXPLORE_PAGE_MODES.SEARCH:
        return <SearchPanel defaultFilter={redirectFilter} {...genericProps} />;
      case EXPLORE_PAGE_MODES.FAVOURITE:
        return <FavouritePanel {...genericProps} />;
      default:
        break;
    }
  };
  return <div className="mt-lg-7 mt-4 pt-6">{switchMode(mode)}</div>;
};

export default ExploreApp;
