import { useCookieState } from "ahooks";
import dayjs from "dayjs";
import React, { useEffect, useState } from "react";
import { Alert } from "react-bootstrap";
import { loadCurrentUserDetail } from "../../actions";
import {
  GA_ACTIONS,
  ILLUSTRATIONS,
  USER_PLAN_TYPES,
} from "../../utils/constants";
import {
  sendGAGenericEvent,
  setGAUserId,
} from "../../utils/thirdParty/GoogleAnalyticsUtils";

const PagePlanExpired = () => {
  const [userDetailData, setUserDetailData] = useState(null);

  const setToken = useCookieState("escs")[1];
  const setTokenExp = useCookieState("exp")[1];
  const logout = () => {
    setToken(null);
    setTokenExp(null);
    window.onbeforeunload = null;
    window.location.href = "/";
  };

  useEffect(() => {
    loadCurrentUserDetail(
      (data) => {
        setGAUserId(data.id);
        sendGAGenericEvent(
          data.id,
          GA_ACTIONS.AUTHENTICATION.SUBSCRIPTION_EXPIRED.toLowerCase(),
          null
        );
        setUserDetailData(data);
      },
      logout,
      true,
      true
    );
  }, []);

  const getActionText = (userDetailData) => {
    let actionText = "re-start your subscription"; // For subscription
    if (userDetailData?.userInfo.plan === "TRIAL")
      actionText = "purchase a subscription";
    else if (userDetailData?.userInfo.plan === "PREVIEW")
      actionText = "start a trial";
    return actionText;
  };

  const getMessage = (userDetailData) => {
    if (
      userDetailData?.userInfo.planStart &&
      dayjs().isBefore(userDetailData?.userInfo.planStart, "day")
    )
      return (
        <span>
          Your Cura subscription hasn't started yet.
          <br />
          You can't view units or create a classroom until your subscription
          starts.
          <br />
          To gain access now,
        </span>
      );
    return (
      <span>
        Your Cura{" "}
        {userDetailData?.userInfo.plan
          ? USER_PLAN_TYPES[
              userDetailData.userInfo.plan
            ].displayText.toLowerCase()
          : USER_PLAN_TYPES.BASIC_SUBSCRIPTION.displayText.toLocaleLowerCase()}{" "}
        has expired. <br />
        {userDetailData?.userInfo.plan === USER_PLAN_TYPES.PREVIEW.value
          ? "You can continue exploring & using Cura's units with a trial subscription."
          : "You can't access your previous classrooms or work without an active subscription."}{" "}
        <br />
        To {getActionText(userDetailData)},
      </span>
    );
  };

  return (
    userDetailData && (
      <div
        style={{
          background: "url('/img/explore_screenshot.jpg') no-repeat fixed",
          backgroundSize: "100%",
        }}
        className="min-vh-100 h-100vh"
      >
        <div
          style={{
            backgroundColor: "rgba(0, 0, 0, 0.6)",
          }}
          className="min-vh-100 h-100vh"
        >
          <div className="d-flex">
            <Alert variant="danger" className="mx-auto" style={{ top: "1rem" }}>
              <img
                className="cursor-pointer"
                src={ILLUSTRATIONS.LOGO.MAIN_LOGO}
                alt="logo"
                height="24"
                onClick={logout}
              />
              <br />
              <br />
              {getMessage(userDetailData)}{" "}
              <a
                href="mailto:support@curaeducation.com"
                className="text-underline"
              >
                email us
              </a>{" "}
              at{" "}
              <span className="font-weight-bolder">
                support@curaeducation.com
              </span>{" "}
              to request access.
              <br />
              <br />
              <div className="float-right">
                <a
                  className="text-underline font-weight-bolder"
                  href="/"
                  onClick={logout}
                >
                  Back to Login
                </a>
              </div>
            </Alert>
          </div>
        </div>
      </div>
    )
  );
};

export default PagePlanExpired;
