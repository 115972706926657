import React, { useState, useEffect } from "react";
import { loadCurrentUserDetail, loadUserRegisterStatus } from "../../actions";
import { useCookieState } from "ahooks";
import { useDispatch } from "react-redux";
import { USER_DETAIL } from "../../actions/types";
import { useHistory } from "react-router-dom";

import { RoleType, UserRegisterStatus } from "../../utils/constants";

const InvitationPage = (props) => {
  const dispatch = useDispatch();
  const [isExpired, setIsExpired] = useState(false);

  const setToken = useCookieState("escs")[1];
  const setTokenExp = useCookieState("exp")[1];
  const history = useHistory();
  const onHandleAlreadyLogin = (data) => {
    switch (data.userInfo.roleTypeName) {
      case RoleType.ADMIN:
        history.push("/admin/dashboard");
        break;
      case RoleType.TEACHER:
        history.push("/teacher");
        break;
      default:
        history.push("/student");
        break;
    }
  };

  const clearTokenCookies = () => {
    setToken(null);
    setTokenExp(null);
  };
  const onHandleResetPassword = () => {
    clearTokenCookies();
    window.location.href = "/password/reset-link/" + props.match.params.token;
  };
  const onHandleLogout = () => {
    clearTokenCookies();
    window.location.href = "/";
  };

  useEffect(() => {
    loadCurrentUserDetail(
      (data) => {
        dispatch({ type: USER_DETAIL, payload: data });
        onHandleAlreadyLogin(data);
      },
      () => {
        loadUserRegisterStatus(
          { token: props.match.params.token },
          (data) => {
            data.status === UserRegisterStatus.NEW
              ? onHandleResetPassword()
              : onHandleLogout();
          },
          () => setIsExpired(true)
        );
      },
      // Shouldn't logout the user if a 401 error coms out. Should go for the registration flow.
      // shouldAuthErrorLogout
      false
    );
  }, []);
  return (
    <div className="text-center">
      <div className="AuthPanel">
        {isExpired ? (
          <h4>
            This invitation link has expired. <br />
            If you are a teacher, please email support@curaeducation.com and
            request a new link. If you are a student, please ask your teacher to
            send you a new link.
          </h4>
        ) : (
          <h1>Please wait...</h1>
        )}
        <a href="/">Back to login</a>
      </div>
    </div>
  );
};

export default InvitationPage;
