import React, { useState } from "react";
import { Form, Button } from "react-bootstrap";
import { useToasts } from "react-toast-notifications";
import { useCookieState } from "ahooks";
import { otpSignIn, otpResend } from "../../actions";
import { MAX_COOKIE_AGE } from "../../utils/constants";
import OTPSubmitForm from "./OTPSubmitForm";

const OTPSignIn = ({ credential, handleUserDetailChange }) => {
  const setToken = useCookieState("escs")[1];
  const setTokenExp = useCookieState("exp")[1];
  const { addToast } = useToasts();
  const [invalidType, setInvalidType] = useState(null);
  const [currentCredential, setCurrentCredential] = useState(credential);
  const [otpInValid, setOtpInValid] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const handleOTPLoginSubmit = (event) => {
    event.preventDefault();
    setOtpInValid(false);
    setInvalidType(null);
    const form = event.target;
    if (currentCredential == null)
      addToast("Something went wrong. Please try again later.", {
        appearance: "error",
        autoDismiss: true,
      });
    const data = {
      token: currentCredential,
      otp_str: form.elements.code.value,
    };
    setIsSubmitting(true);
    otpSignIn(
      data,
      (data) => {
        const exp = new Date(+new Date() + MAX_COOKIE_AGE);
        setToken(data.token, {
          // Expire after 1 day
          expires: (() => exp)(),
        });
        setTokenExp(exp);
        handleUserDetailChange(data.user);
        setIsSubmitting(false);
      },
      (data) => {
        setOtpInValid(true);
        if (Array.isArray(data) && data.length === 1 && data[0] === "EXPIRED")
          setInvalidType(1);
        else setInvalidType(2);
        setIsSubmitting(false);
      }
    );
  };

  const handleResendOTP = () => {
    setOtpInValid(false);
    setInvalidType(null);
    const data = {
      token: currentCredential,
    };
    otpResend(
      data,
      (data) => {
        addToast(
          "We've sent a security code to your registered email address." +
            " Please enter the code to sign in.",
          {
            appearance: "success",
            autoDismiss: true,
          }
        );
        setCurrentCredential(data["token"]);
      },
      (data) => {
        window.location.reload();
      }
    );
  };
  return (
    <>
      <Form.Row>
        <Button
          variant="outline-primary"
          className="rounded-pill mb-3"
          size="xs"
          onClick={() => window.location.reload()}
        >
          ← Back
        </Button>
        <p className="text-dark text-sm">
          We've sent a security code to your registered email address. Please
          enter the code to sign in.
        </p>
      </Form.Row>
      <OTPSubmitForm
        handleSubmit={handleOTPLoginSubmit}
        handleResend={handleResendOTP}
        isSubmitting={isSubmitting}
        otpInValid={otpInValid}
        invalidType={invalidType}
      />
    </>
  );
};

export default OTPSignIn;
