import React, { useMemo, useState } from "react";
import { faMagnifyingGlass } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faHeart as faHeartRegular } from "@fortawesome/free-regular-svg-icons";
import { faHeart } from "@fortawesome/free-solid-svg-icons";
import { Button, Card } from "react-bootstrap";
import {
  AWS_REGION_COUNTRY_MAP,
  COUNTRY_INFO,
  EXPLORE_PAGE_MODES,
} from "../../utils/constants";
import { deepCopy } from "../../utils/GenericUtils";
import { ExploreCourseCard } from "../../components/course/CourseCard";
import HorizontalGallery from "../../components/util/HorizontalGallery";
import BoldHeaderWithSideItems from "../util/BoldHeaderWithSideItems";
import OverviewModal from "./OverviewModal";
import Can from "../../utils/Can";

const REGION_STR = AWS_REGION_COUNTRY_MAP[window._env_["REGION"]];

const ExplorePanel = ({
  courseList,
  setMode,
  handleFilterUpdate,
  isPublic = false,
}) => {
  const [showCourseModal, setShowCourseModal] = useState(null);
  const getMultipleRandom = (arr, num) => {
    const shuffled = [...arr].sort(() => 0.5 - Math.random());
    return shuffled.slice(0, num);
  };

  const FeaturedCourseDisplay = ({ courses, title, titleColor = "dark" }) => {
    const FilterCard = () => (
      <Card
        className={
          "border-0 card-hover-overlay hover-shadow-lg " +
          "no-text-select cursor-pointer bg-warning text-center"
        }
        style={{
          boxShadow: "0 0.75rem 1.5rem rgb(18 38 63 / 10%)",
          height: "calc(100% - 1.25rem)",
        }}
        onClick={() => {
          if (title === "New") handleFilterUpdate({});
          else handleFilterUpdate({ subject: title });
          setMode(EXPLORE_PAGE_MODES.SEARCH);
          window.scrollTo(0, 0);
        }}
      >
        <div className="my-auto">
          <Card.Title className="mx-3 text-white mb-5">
            All {title} courses
          </Card.Title>
          <div className="w-100 h-100 text-center">
            <Card.Img
              className="mt-auto mb-2"
              variant="bottom"
              style={{ width: "12rem" }}
              src={
                COUNTRY_INFO[REGION_STR].exploreFeaturedSubjects.find(
                  (item) => item.subjectName === title
                ).image
              }
            />
          </div>
        </div>
      </Card>
    );
    return (
      <div className="mb-4">
        <h3 className={`text-${titleColor} ml-4 ml-xl-5 pl-lg-2 mb-3`}>
          {title}
        </h3>
        <HorizontalGallery>
          {courses.map((course) => {
            const project = courseList.projectDict[course.projectRef];
            return (
              <ExploreCourseCard
                itemId={course.courseRef} // NOTE: itemId is required for track items
                key={course.courseRef}
                course={course}
                project={project}
                onClick={() =>
                  setShowCourseModal({ course: course, project: project })
                }
              />
            );
          })}
          {title !== "New" && <FilterCard />}
        </HorizontalGallery>
      </div>
    );
  };

  const randomFeaturedSubjectsCourseList = useMemo(
    () =>
      courseList &&
      COUNTRY_INFO[REGION_STR].exploreFeaturedSubjects.map((subject) => {
        return {
          subject: subject.subjectName,
          courses: getMultipleRandom(
            courseList.courses.filter((course) =>
              course.countryMetadata[REGION_STR].subject.includes(
                subject.subjectName
              )
            ),
            7
          ),
        };
      }),
    []
  );

  const randomFeaturedNewCourseList = useMemo(
    () =>
      courseList &&
      deepCopy(courseList.courses)
        .sort((a, b) => (a.createAt < b.createAt ? 1 : -1))
        .slice(0, 7),
    []
  );

  const subjectCourseList = useMemo(
    () =>
      courseList && (
        <>
          <FeaturedCourseDisplay
            key="New"
            courses={randomFeaturedNewCourseList}
            title="New"
            titleColor="white"
          />
          {randomFeaturedSubjectsCourseList.map((item) => (
            <FeaturedCourseDisplay
              key={item.subject}
              courses={item.courses}
              title={item.subject}
            />
          ))}
        </>
      ),
    []
  );

  return (
    <>
      <BoldHeaderWithSideItems title="Explore Courses">
        <Can
          perform="favourite-course:teacher"
          yes={() => (
            <>
              <div
                className="d-lg-none text-light p-2 cursor-pointer"
                onClick={() => setMode(EXPLORE_PAGE_MODES.FAVOURITE)}
              >
                <FontAwesomeIcon className="mt-2" icon={faHeartRegular} />
              </div>
              <Button
                className="d-none d-lg-block mr-3 rounded-pill"
                variant="outline-light"
                size="sm"
                onClick={() => setMode(EXPLORE_PAGE_MODES.FAVOURITE)}
              >
                <FontAwesomeIcon className="mr-lg-2" icon={faHeart} />
                <span className="d-none d-lg-inline">My Favourites</span>
              </Button>
            </>
          )}
          no={() => null}
        />

        <div
          className="d-lg-none text-light mx-0 p-2 mr-2 cursor-pointer"
          onClick={() => setMode(EXPLORE_PAGE_MODES.SEARCH)}
        >
          <FontAwesomeIcon className="mt-2" icon={faMagnifyingGlass} />
        </div>
        <Button
          className="d-none d-lg-block mr-5 text-light rounded-pill"
          variant="warning"
          size="sm"
          onClick={() => setMode(EXPLORE_PAGE_MODES.SEARCH)}
        >
          <FontAwesomeIcon className="mr-lg-2 m-0" icon={faMagnifyingGlass} />
          <span className="d-none d-lg-inline">Search Courses</span>
        </Button>
      </BoldHeaderWithSideItems>
      <OverviewModal
        show={showCourseModal}
        setShow={setShowCourseModal}
        isPublic={isPublic}
      />
      <div className="text-center">
        {COUNTRY_INFO[REGION_STR].exploreFeaturedSubjects.map((item, index) => (
          <span key={index} className="mr-3">
            <Button
              variant="outline-light rounded-pill mb-3"
              size="sm"
              onClick={() => {
                handleFilterUpdate({ subject: item.subjectName });
                setMode(EXPLORE_PAGE_MODES.SEARCH);
              }}
            >
              {item.subjectName}
            </Button>
          </span>
        ))}
      </div>
      <div className="pb-lg-4" />
      {subjectCourseList}
    </>
  );
};

export default ExplorePanel;
