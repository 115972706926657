import React, { useState, useEffect } from "react";
import { Prompt } from "react-router";
import { Button, Form, Tab, Tabs, Image, Spinner } from "react-bootstrap";
import { useToasts } from "react-toast-notifications";
import camelCaseRecursive from "../../utils/CamelCaseRecursive";
import { SYSTEM_MESSAGES, TaskContentType } from "../../utils/constants";
import { uploadFileWithHandler } from "../../utils/FileUpload";
import RichTextEditor from "../util/RichTextEditor";
import MultiFilesUpload from "../util/MultiFilesUpload";
import { getUploadDefaultFileList } from "../util/DataConverter";
import {
  loadTaskDetail,
  createTask,
  updateTask,
  loadTaskExistsActiveCourseExec,
} from "../../actions";
import ProjectListDropDown from "../project/ProjectListDropdown";
import { isFileNameTooLong } from "../../utils/GenericUtils";
import {
  handleGenericSaveFailed,
  handleGenericSaveSuccess,
} from "../../utils/CreateEditFormUtils";
import TaskResourceBlockEditor from "../task/resourceBlock/TaskResourceBlockEditor";

const TaskCreateEditForm = ({ taskRef, mode, onSaveSuccess }) => {
  const { addToast } = useToasts();
  const [taskDetailData, setTaskDetailData] = useState(null);
  const [updatedTaskDetail, setUpdatedTaskDetail] = useState({
    body: null,
    bodyFiles: [],
    bodyFilesNewUploads: [],
    teacherContent: null,
    teacherContentFiles: [],
    teacherContentFilesNewUploads: [],
    taskResourceBlockList: null,
  });
  const [thumbnailFile, setThumbnailFile] = useState(null);
  const [thumbnailPreviewSrc, setThumbnailPreviewSrc] = useState(null);
  const [isProceedClicked, setIsProceedClicked] = useState(false);
  const [selectedProjectRef, setSelectedProjectRef] = useState(null);
  const [isUpdated, setIsUpdated] = useState(false);

  useEffect(() => {
    if (isUpdated) window.onbeforeunload = () => true;
    else window.onbeforeunload = null;
  }, [isUpdated]);

  useEffect(() => {
    if (mode === "edit") {
      loadTaskDetail(taskRef, (data) => {
        updateTaskDetailStates(data);
        setThumbnailPreviewSrc(data.thumbnail);
        setSelectedProjectRef(data.projectRef);
      });
    }
  }, [taskRef, mode]);

  const handleEdited = () => setIsUpdated(true);

  const handleThumbnailChagne = (event) => {
    const file = event.target.files[0];
    setThumbnailFile(file);
    setThumbnailPreviewSrc(URL.createObjectURL(file));
    handleEdited();
  };

  const updateTaskDetailStates = (responseData) => {
    const data = camelCaseRecursive(responseData);
    setTaskDetailData(data);
    setUpdatedTaskDetail({
      ...updatedTaskDetail,
      bodyFiles: data.bodyAttachmentList,
      teacherContentFiles: data.teacherContentAttachmentList,
    });
  };

  // Upload files and create taskAttachment links for creating/updating task
  const getUploadedLinkList = async (fileList) => {
    let resultList = [];
    for (const file of fileList) {
      await uploadFileWithHandler(
        file,
        (url) => {
          resultList.push({
            taskAttachmentLinkRef: file.uid + "_link",
            attachmentRef: file.uid,
            attachment: {
              attachmentRef: file.uid,
              fileName: file.name,
              url: url,
              status: "done",
            },
          });
        },
        () => {
          // TODO: handle file upload error
        },
        "task-attachment",
        false
      );
    }
    return resultList;
  };

  const handleSubmitSuccess = (data) => {
    updateTaskDetailStates(data);
    setIsProceedClicked(false);
    handleGenericSaveSuccess(
      addToast,
      { object: "task", operation: "saved" },
      onSaveSuccess
    );
  };

  const handleSubmitFail = () => {
    setIsProceedClicked(false);
    handleGenericSaveFailed(addToast);
  };

  const isAnyFilenameTooLong = (fileList) => {
    for (const file of fileList) {
      if (isFileNameTooLong(file.name)) return true;
    }
    return false;
  };

  const submitData = async (form) => {
    if (
      isAnyFilenameTooLong(updatedTaskDetail.bodyFilesNewUploads) ||
      isAnyFilenameTooLong(updatedTaskDetail.teacherContentFilesNewUploads)
    ) {
      alert(SYSTEM_MESSAGES.ERROR.EXEEDED_MAX_FILENAME_SIZE);
      return;
    }

    setIsProceedClicked(true);
    let thumbnail = null;
    if (!thumbnailFile) {
      thumbnail = taskDetailData.thumbnail;
    } else {
      const url = await uploadFileWithHandler(
        thumbnailFile,
        (fileURI) => setThumbnailPreviewSrc(fileURI),
        handleSubmitFail,
        "task-thumbnail",
        false
      );
      if (!url) return;
      thumbnail = url;
    }
    const projectRef =
      selectedProjectRef === "no-project" ? null : selectedProjectRef;
    const data = {
      title: form.elements.title.value,
      versionName: form.elements.versionName.value,
      description: form.elements.description.value,
      note: form.elements.note.value,
      suggestedTime: form.elements.suggestedTime.value
        ? parseInt(form.elements.suggestedTime.value)
        : null,
      projectRef: projectRef,
      thumbnail: thumbnail,
      body: updatedTaskDetail.body
        ? updatedTaskDetail.body
        : taskDetailData
        ? taskDetailData.body
        : "",
      bodyAttachmentList: updatedTaskDetail.bodyFiles.concat(
        await getUploadedLinkList(updatedTaskDetail.bodyFilesNewUploads)
      ),
      teacherContent: updatedTaskDetail.teacherContent
        ? updatedTaskDetail.teacherContent
        : taskDetailData
        ? taskDetailData.teacherContent
        : "",
      teacherContentAttachmentList: updatedTaskDetail.teacherContentFiles.concat(
        await getUploadedLinkList(
          updatedTaskDetail.teacherContentFilesNewUploads
        )
      ),
      taskResourceBlockList: updatedTaskDetail.taskResourceBlockList
        ? updatedTaskDetail.taskResourceBlockList
        : taskDetailData
        ? taskDetailData.taskResourceBlockList
        : null,
    };

    setThumbnailFile(null);
    switch (mode) {
      case "edit":
        data.taskRef = taskRef;
        updateTask(
          data,
          (data) => {
            // TODO: Fix this rerendering
            handleSubmitSuccess(data);
            setIsUpdated(false);
          },
          handleSubmitFail
        );
        break;
      case "create":
        createTask(
          data,
          (data) => {
            handleSubmitSuccess(data);
            window.onbeforeunload = null;
            setTimeout(() => {
              window.location.href = `/admin/tasks/task/edit/${data.taskRef}`;
            }, 2000);
          },
          handleSubmitFail
        );
        break;
      default:
        console.warn(`No mode called: ${mode}`);
    }
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    if (thumbnailPreviewSrc === null) {
      alert("Please select a thumbnail.");
      return;
    }
    const form = event.target;
    if (mode === "edit")
      loadTaskExistsActiveCourseExec(
        { taskRef: taskRef },
        (data) => {
          const message =
            `This version of "${form.elements.title.value}"` +
            ` is part of a course currently in progress. ` +
            `If you update this task, it will update the courses in progress.\n\n` +
            `Are you sure you want to update this version?`;
          if (data.exists && !window.confirm(message)) {
            return;
          }
          submitData(form);
        },
        () => handleGenericSaveFailed(addToast)
      );
    else submitData(form);
  };

  // Handle editor change to update states
  const handleEditorChange = (editorType) => {
    return (content) => {
      if (editorType === TaskContentType.BODY) {
        setUpdatedTaskDetail({ ...updatedTaskDetail, body: content });
      } else {
        setUpdatedTaskDetail({
          ...updatedTaskDetail,
          teacherContent: content,
        });
      }
      handleEdited();
    };
  };

  // Callback function for MultiFilesUpload component
  const appendFileForSaving = (link_type) => {
    return (file) => {
      handleEdited();
      let fileNewUploadList =
        link_type === TaskContentType.BODY
          ? updatedTaskDetail.bodyFilesNewUploads
          : updatedTaskDetail.teacherContentFilesNewUploads;
      fileNewUploadList.push(file);

      if (link_type === TaskContentType.BODY) {
        setUpdatedTaskDetail({
          ...updatedTaskDetail,
          bodyFilesNewUploads: fileNewUploadList,
        });
      } else {
        setUpdatedTaskDetail({
          ...updatedTaskDetail,
          teacherContentFilesNewUploads: fileNewUploadList,
        });
      }
    };
  };

  // Callback function for MultiFilesUpload component
  const removeFileLinkForSaving = (link_type) => {
    return (link_ref) => {
      handleEdited();
      let fileNewUploadList =
        link_type === TaskContentType.BODY
          ? updatedTaskDetail.bodyFilesNewUploads
          : updatedTaskDetail.teacherContentFilesNewUploads;
      for (const index in fileNewUploadList) {
        if (link_ref === fileNewUploadList[index].uid) {
          fileNewUploadList.splice(index, 1);
          break;
        }
      }
      let attachmentLinkList =
        link_type === TaskContentType.BODY
          ? updatedTaskDetail.bodyFiles
          : updatedTaskDetail.teacherContentFiles;
      for (const index in attachmentLinkList) {
        if (link_ref === attachmentLinkList[index].taskAttachmentLinkRef) {
          attachmentLinkList.splice(index, 1);
          break;
        }
      }
      if (link_type === TaskContentType.BODY) {
        setUpdatedTaskDetail({
          ...updatedTaskDetail,
          bodyFiles: attachmentLinkList,
          bodyFilesNewUploads: fileNewUploadList,
        });
      } else {
        setUpdatedTaskDetail({
          ...updatedTaskDetail,
          teacherContentFiles: attachmentLinkList,
          teacherContentFilesNewUploads: fileNewUploadList,
        });
      }
    };
  };

  const handleSingleLineInputKeyDown = (e) => {
    if (e.key === "Enter") e.preventDefault();
    handleEdited();
  };

  return (
    ((mode === "edit" && taskDetailData) || mode === "create") && (
      <Form onSubmit={handleSubmit} className="text-dark">
        <Prompt
          when={isUpdated}
          message="You will lose all unsaved progress. Are you sure you want to leave?"
        />
        <Form.Group
          controlId="formName"
          onKeyDown={handleSingleLineInputKeyDown}
        >
          <Form.Label>Task Name</Form.Label>
          <Form.Control
            className="text-dark"
            required
            type="text"
            placeholder="e.g. The Big Dry"
            name="title"
            defaultValue={mode === "edit" ? taskDetailData.title : ""}
          />
        </Form.Group>
        <Form.Group
          controlId="formVersionName"
          onKeyDown={handleSingleLineInputKeyDown}
        >
          <Form.Label>Version Name</Form.Label>
          <Form.Control
            className="text-dark"
            required
            type="text"
            name="versionName"
            defaultValue={mode === "edit" ? taskDetailData.versionName : "v1"}
          />
        </Form.Group>
        <Form.Group
          controlId="formDescription"
          onKeyDown={handleSingleLineInputKeyDown}
        >
          <Form.Label>Task Requirement</Form.Label>
          <Form.Control
            className="text-dark"
            type="text"
            name="description"
            defaultValue={mode === "edit" ? taskDetailData.description : ""}
          />
        </Form.Group>
        <Form.Group
          controlId="formProjectRef"
          onKeyDown={handleSingleLineInputKeyDown}
        >
          <Form.Label>Project</Form.Label>
          <br />
          <ProjectListDropDown
            onProjectChange={(value) => {
              setSelectedProjectRef(value);
              handleEdited();
            }}
            defaultValue={
              (mode === "edit" && taskDetailData.projectRef == null) ||
              mode === "create"
                ? "no-project"
                : taskDetailData.projectRef
            }
          />
        </Form.Group>
        <Form.Group
          controlId="formSuggestedTime"
          onKeyDown={handleSingleLineInputKeyDown}
        >
          <Form.Label>Suggested Time (minutes):</Form.Label>
          <Form.Control
            className="text-dark"
            type="number"
            name="suggestedTime"
            min="0"
            max="65535"
            defaultValue={mode === "edit" ? taskDetailData.suggestedTime : ""}
          />
        </Form.Group>
        <Form.Group>
          <Form.Label>Banner Image</Form.Label>
          <Form.File id="thumbnailImage" onChange={handleThumbnailChagne} />
          <Form.Text id="imageHelpBlock" muted>
            Recommended image ratio: <b className="text-dark">3:1</b>, file size
            less than <b className="text-dark">800KB</b>. <br />
            Before uploading, crop the image, and use an{" "}
            <a
              href="https://compressjpeg.com/"
              target="_blank"
              rel="noreferrer"
            >
              <u>Online Image Compressor</u>
            </a>{" "}
            to reduce the size.
          </Form.Text>
          {thumbnailPreviewSrc ? (
            <Image width={300} src={thumbnailPreviewSrc} rounded />
          ) : null}
        </Form.Group>
        <Form.Group>
          <Form.Label>Notes</Form.Label>
          <Form.Control
            className="text-dark"
            as="textarea"
            name="note"
            rows={2}
            defaultValue={mode === "edit" ? taskDetailData.note : ""}
            onChange={handleEdited}
          />
        </Form.Group>
        <Tabs
          className="ml-1"
          defaultActiveKey="body"
          id="uncontrolled-tab-example"
        >
          <Tab eventKey="body" title="Task Overview">
            <div className="mt-4">
              <RichTextEditor
                data={mode === "edit" ? taskDetailData.body : ""}
                onChange={handleEditorChange(TaskContentType.BODY)}
              />
              <MultiFilesUpload
                uploadKey={TaskContentType.BODY}
                appendFileForSaving={appendFileForSaving(TaskContentType.BODY)}
                removeFileLinkForSaving={removeFileLinkForSaving(
                  TaskContentType.BODY
                )}
                defaultFileList={
                  taskDetailData
                    ? getUploadDefaultFileList(
                        taskDetailData.bodyAttachmentList
                      )
                    : []
                }
              />
            </div>
            <div className="mt-3">
              <TaskResourceBlockEditor
                blocks={
                  taskDetailData ? taskDetailData.taskResourceBlockList : null
                }
                onUpdate={(blocks) =>
                  setUpdatedTaskDetail({
                    ...updatedTaskDetail,
                    taskResourceBlockList: blocks,
                  })
                }
              />
            </div>
          </Tab>
          <Tab eventKey="teaching" title="Teacher Notes">
            <div className="mt-4">
              <RichTextEditor
                data={mode === "edit" ? taskDetailData.teacherContent : ""}
                onChange={handleEditorChange(TaskContentType.TEACHER_CONTENT)}
              />
              <MultiFilesUpload
                uploadKey={TaskContentType.TEACHER_CONTENT}
                appendFileForSaving={appendFileForSaving(
                  TaskContentType.TEACHER_CONTENT
                )}
                removeFileLinkForSaving={removeFileLinkForSaving(
                  TaskContentType.TEACHER_CONTENT
                )}
                defaultFileList={
                  taskDetailData
                    ? getUploadDefaultFileList(
                        taskDetailData.teacherContentAttachmentList
                      )
                    : []
                }
              />
            </div>
          </Tab>
        </Tabs>
        <Button
          className="mt-5"
          size="md"
          type="submit"
          disabled={isProceedClicked}
          block
        >
          {isProceedClicked ? (
            <Spinner animation="grow" size="sm" />
          ) : mode === "create" ? (
            "Create"
          ) : (
            "Update & Preview"
          )}
        </Button>
      </Form>
    )
  );
};

export default TaskCreateEditForm;
