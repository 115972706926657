import React from "react";
import { useParams } from "react-router-dom";
import CourseView from "../../components/course/CourseView";
import Header from "../../components/util/Header";

const CoursePreviewPage = () => {
  const { courseRef } = useParams();
  return (
    <>
      <Header />
      <CourseView courseRef={courseRef} isPreview={true} />
    </>
  );
};

export default CoursePreviewPage;
